import React, { useState, useEffect } from "react";
import api from "../tools/api/axios";
import { Table, Row, Col, Modal, Button } from "react-bootstrap";

const endpoint = "/envmgr/nex7-env"

const EnvView = () => {

    const [localEnv, setLocalEnv] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [envKey, setEnvKey] = useState("")
    const [envVal, setEnvVal] = useState("")


    useEffect(() => {
        getLocalEnv()
    }, [])

    const getLocalEnv = () => {
        api.get(endpoint).then(res => {
            if (res.data) {
                var arr = []
                Object.keys(res.data).map((k, v) => {
                    console.log(k, res.data[k], res.data[v])
                    arr.push({ key: k, val: res.data[k] })
                })
                setLocalEnv(arr)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const saveEnv = (k, v) => {
        console.log(envKey, envVal)
        api.post(`${endpoint}/${k}`, v).then(res => {
            getLocalEnv()
            setShowModal(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const editEnv = (rec) => {
        setEnvKey(rec.key)
        setEnvVal(rec.val)
        setShowModal(true)
    }

    return (
        <div>
            <Button style={{marginBottom: '10px'}} variant="primary" onClick={() => { setShowModal(true); setEnvKey(""); setEnvVal("") }}>Add Environment Variable</Button>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(localEnv).map(key => {
                        console.log(key)
                        return (
                            <tr>
                                <td>{localEnv[key].key}</td>
                                <td>{localEnv[key].val}</td>
                                <td>
                                    <span>
                                        <Row>
                                            <Col>
                                                <span onClick={() => { editEnv(localEnv[key]) }} style={{ color: 'blue' }}>Edit</span>
                                            </Col>
                                            <Col>
                                                <span onClick={() => { saveEnv(localEnv[key].key, "") }} style={{ color: 'red' }}>Delete</span>
                                            </Col>
                                        </Row>
                                    </span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <Modal show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Environment Variable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <input placeholder={envKey} onChange={(e) => { setEnvKey(e.target.value) }} />
                        </Col>
                        <Col>
                            <input placeholder={envVal} onChange={(e) => { setEnvVal(e.target.value) }} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"  onClick={() => { setShowModal(false) }}>Cancel</Button>
                    <Button variant="success" onClick={() => { saveEnv(envKey, envVal) }}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EnvView