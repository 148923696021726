import React, { Component } from 'react'
import { Button, Row, Col, ProgressBar, Jumbotron } from 'react-bootstrap'
import { FaUpload } from 'react-icons/fa'
import { NotificationManager } from 'react-notifications';
import api from '../api/axios'

class Uploader extends Component {

  constructor(props){
    super(props)
    this.state = {
      uploadProgresses: {},
      isUploading: false,
      uploadAndDeploy: false,
      uploadName: "",
    }
  }

  upload (data, fileName) {
    var config = {
      onUploadProgress: (evt) => {
        let percentCompleted = Math.round( (evt.loaded * 100) / evt.total )
        let uploadProgresses = this.state.uploadProgresses
        if (percentCompleted < 100) {
          uploadProgresses[fileName] = percentCompleted
        } else {
          delete uploadProgresses[fileName]
        }
        this.setState({ uploadProgresses })
      }
    }
    let url = '/pkgs/add'
    if(this.state.uploadAndDeploy) url=url + "?deploy=true"
    
    return api.put(url, data, config)
      .then(res => {
        NotificationManager.success(fileName + ' uploaded', 'Upload success');
      })
      .catch(err => {
        console.log('err err', err)
        NotificationManager.error('Unable to upload ' + fileName, 'Upload error', 10000);
      })
  }

  handleUpload (e) {
    e.preventDefault()
    this.setState({ isUploading: true })
    let promises = []
    for (let i = 0; i < this.uploadInput.files.length; i++) {
      let fileName = this.uploadInput.files[i].name
      const data = new FormData()
      data.append('package', this.uploadInput.files[i])
      try {
        let res = this.upload(data, fileName)
        this.setState({uploadName: fileName})
        promises.push(res)
        console.log(res)
      } catch (e) {
        console.log('error e', e)
      }
    }

    Promise.all(promises).then(() => {
      this.setState({ isUploading: false })
      this.props.getRoles()
    })
  }

  uploadClicked () { this.uploadInput.click() }

  fileSelected (e) {
      this.handleUpload(e)
      this.uploadForm.reset()
  }

  uploadAndDeploy(){
    this.setState({uploadAndDeploy: !this.state.uploadAndDeploy})
  }

  render() {
    return(
      <div>
        <Jumbotron className="text-center p-2" style = {{background: 'white'}}>
          <Row>
            <Col md="12" style = {{ padding: 5 }}>
              <form className="inline" ref={(el) => { this.uploadForm = el }}
                onSubmit={this.handleUpload.bind(this)}
                id='package'
                encType='multipart/form-data'
                >
                <input
                  type='file'
                  name='package'
                  ref={(ref) => { this.uploadInput = ref }}
                  style={{display: 'none'}}
                  onChange={this.fileSelected.bind(this)}
                  multiple
                  />
                <Button variant="outline-primary" onClick={this.uploadClicked.bind(this)} disabled={this.state.isUploading} size='sm'>
                  <FaUpload /> { this.state.isUploading ? 'Uploading...' : 'Upload' }
                  </Button>
                </form>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <input type='checkbox' id="depCheck" checked={this.state.uploadAndDeploy} onChange={this.uploadAndDeploy.bind(this)} />
                <label for="depCheck">Deploy after uploading</label>
              </Col>
            </Row>
        </Jumbotron>
        <Row>
          <Col md="12" style={{ padding: 5 }}>
            {
              Object.keys(this.state.uploadProgresses).map(k => {
                let now = this.state.uploadProgresses[k]
                return (
                  <div key={k} style={{ marginBottom: 10 }}>
                    <span>{ k }</span>
                    <ProgressBar now={now} label={`${now}%`} />
                  </div>
                )
              })
            }
          </Col>
        </Row>
    </div>
    )
  }
}

export {Uploader}
