import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Card, Col, Form, Row, Table, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import SelectableContext from 'react-bootstrap/SelectableContext'
import { FaTimes, FaShare, FaTrashAlt, FaCaretUp, FaCaretDown, FaDownload } from 'react-icons/fa'
import { NotificationContainer } from 'react-notifications';
import Select from 'react-select'
import _ from 'lodash'
import api from '../tools/api/axios'
import axios from '../tools/api/axios'
import { CreateGroup, EditGroup, Uploader } from '../tools/role/RoleTools'
import Manifest from '../tools/role/Manifest'

const stagingRole = "Staged-Apps";

const preferenceMap = {
  0: 'Default',
  1: 'Medium',
  2: 'High',
  3: 'Highest'
};

const options = [
  {value: 0, label: "All"},
  {value: 1, label: "Bridge Controller Role"},
  {value: 2, label: "Coordinator Role"},
  {value: 3, label: "Player Tracking Role"},
  {value: 4, label: "Display Role"},
  {value: 5, label: "API Role"},
]

const sortRoles = (a, b) => {
    if(a < b)  {
        return -1
    } else if(b < a) {
        return 1;
    }
    return 0;
}

class RoleMgr extends Component {

    constructor (props) {
        super(props)
        this.state = {
          selectedPkgs: {},
          roles: {},
          groups: {},
          allSel: false,
          createRole: false,
          pkgSortField: {},
          pkgSortOrder: {},
          filterData: {data:{value: 0, label: 'All'}},
          filterRegulated: false,
          config: { enhancedVersioning: false }
        }
    }

    componentDidMount(){
      this.getConfig().then(this.getRoles.bind(this))
    }

    handleFilter = data =>{this.setState({filterData: data})}
    getSortOrder (role) {return this.state.pkgSortOrder[role] || 'asc'}

    getConfig () {
      let base = '/'
      if (window.location.hostname !== 'localhost') {base = '/w/apprepo/'}
      return axios.get(base + 'config.json').then(res => {
          if (res.data && res.data.enhancedVersioning) {
            console.log('Enhanced versioning enabled')
          }
          this.setState({ ...this.state, config: res.data })
        }).catch(err => { return err })
    }

    getRoles () {
        return api.get("/pkgs/?time=" + (new Date()).getTime()).then(success => {
            let groups = []
            let roles = success.data
            Object.keys(roles).map(k => {if(roles[k].repo_type === 2){groups.push(roles[k])}return null})
            this.setState(Object.assign({}, this.state, {roles: roles, groups: groups}))
            return
        }, err => {
            console.error(`Encountered error getting roles ${err}`)
            return
        }).catch(err => {
            console.error(`Caught error getting roles ${err}`)
            return
        });
    }

    getSortField (role) {
      let def = ['arch', 'app_name', 'app_version']
      if (this.state.pkgSortField[role]) {
        if (this.state.pkgSortField[role] === 'app_name') {
          return ['app_name', 'arch', 'app_version']
        } else if (this.state.pkgSortField[role] === 'app_version') {
          return ['app_version', 'arch', 'app_name']
        }
      }
      return def
    }

    setSortFieldAndOrder (role, field) {
      let pkgSortField = this.state.pkgSortField
      let pkgSortOrder = this.state.pkgSortOrder
      if (pkgSortField[role] !== field) {
        pkgSortField[role] = field
        pkgSortOrder[role] = 'asc'
      } else {
        pkgSortOrder[role] = this.getSortOrder(role) === 'asc' ? 'desc' : 'asc'
      }
      this.setState({ pkgSortField, pkgSortOrder })
    }

    getSelectedCheckSums () {
      let checkSums = []
      for (let approvedSha1Sum in this.state.selectedPkgs) {
        if (this.state.selectedPkgs[approvedSha1Sum]) {
          checkSums.push(this.state.selectedPkgs[approvedSha1Sum])
        }
      }
      return checkSums
    }

    remove (src, checkSum) {
      let checkSums = checkSum ? [checkSum] : this.getSelectedCheckSums()
      let appNamesAndVersions = []
      checkSums.forEach(cs => {
        appNamesAndVersions.push(cs.app_name + ' ' + cs.app_version)
      })
      let confirmText = 'Are you sure you want to remove the following apps'
      if (src !== stagingRole) {
        confirmText += ' from ' + src
      }
      confirmText += '?\n\n'
      confirmText += appNamesAndVersions.join('\n')
      if (!window.confirm(confirmText)) {
        return
      }
      let route = '/pkgs/remove/' + src
      api.put(route, checkSums)
        .then(res => {
          this.getRoles()
          this.selectNone()
          this.setState({allSel: false})
        })
    }

    selectNone(){
      this.setState({ selectedPkgs: {} })
    }

    deleteGroup(name){
      let confirmText = 'Are you sure you want to delete the group: ' + name + '?\n'
      if (!window.confirm(confirmText)) {
        return
      }
      let route = "/pkgs/delete-group/" + name
      api.delete(route).then(
        res => {
          this.getRoles()
        }
      )
    }

    selectPkg (checkSum) {
      let selectedPkgs = this.state.selectedPkgs
      if (selectedPkgs[checkSum.approved_sha1_sum]) {
        delete selectedPkgs[checkSum.approved_sha1_sum]
      } else {
        selectedPkgs[checkSum.approved_sha1_sum] = checkSum
      }
      console.log(selectedPkgs)
      this.setState({ selectedPkgs })
    }

    selectAllPkg(checksums){
      this.state.allSel ? this.setState({allSel: false}) : this.setState({allSel: true})
      Object.keys(checksums).map(k => {
        return this.selectPkg(checksums[k])
      })
    }

    deploy (dest, sum) {
      var checkSums = []
      console.log(sum)
      checkSums = this.getSelectedCheckSums()

      // check that multiple versions/archs of the same application are selected
      let appNamesAndVersions = []
      let appNamesTmp = []
      for (let i = 0; i < checkSums.length; i++) {
        appNamesTmp.push(checkSums[i].app_name)
        appNamesAndVersions.push(checkSums[i].app_name + ' ' + checkSums[i].app_version)
      }
      // confirm
      let confirmText = 'Are you sure you want to deploy the following apps to ' + dest + '?\n\n'
      confirmText += appNamesAndVersions.join('\n')
      if (!window.confirm(confirmText)) {
        if(sum){this.selectNone()}
        return
      }
      let route = '/pkgs/deploy/' + dest
      api.put(route, checkSums)
        .then(res => {
          this.getRoles()
          this.selectNone()
          this.setState({allSel: false})
        })
    }

    postUploadDeploy(name){
      let tmp = name.replace(".pkg","")
      let replace = tmp.replace("-v", "-")
      this.rapidDeploy('all', this.state.roles[stagingRole].check_sums[replace])
    }

    rapidDeploy(dest, pkg){
      this.selectPkg(pkg)
      this.deploy(dest)
      this.selectNone()
    }

    selectedPkgsCount () {
      let ret = 0
      for (let key in this.state.selectedPkgs) {
        if (this.state.selectedPkgs[key]) {
          ret++
        }
      }
      return ret
    }

    filterStagingPkgs(){
      let stagingRolePkgs = []
      for (let key in this.state.roles[stagingRole].check_sums) {
        let name = key
        let pkg = this.state.roles[stagingRole].check_sums[key]
        if (this.state.filterRegulated && !pkg.is_regulated) {
          continue
        }
        switch (this.state.filterData.value) {
          case 0:
          stagingRolePkgs.push(pkg)
          break;
          case 1:
          if(name.includes("AArch64") || (name.includes(".bin") && name.includes("elf32"))){
            stagingRolePkgs.push(pkg)
          }
          break;
          case 2:
          if(name.includes("elf64")){
            stagingRolePkgs.push(pkg)
          }
          break;
          case 3:
          if(name.includes("elf32") && !name.includes(".bin")){
            stagingRolePkgs.push(pkg)
          }
          break;
          default:
          stagingRolePkgs.push(pkg)
          break;
        }
      }
      return stagingRolePkgs
    }

    pkg (idx, props, role) {
        let pkgUrl = `${api.defaults.baseURL}/pkgs/download/${role}/${props.package.app_name}/${props.package.app_version}/${props.package.arch}`
        return (
            <tr key={idx}>
              <td>
                {
                  props.deployable
                    ? <input type='checkbox' checked={!!this.state.selectedPkgs[props.package.approved_sha1_sum]} onChange={this.selectPkg.bind(this, props.package)} />
                    : null
                }
              </td>
              <td><a href={pkgUrl} target='_new' download>{ props.package.app_name }</a></td>
              <td>{ props.package.arch }</td>
              <td>{ props.package.app_version }</td>
              <td>
                <Table size='sm' borderless style={{ padding: 0, margin: 0 }} className="d-none d-lg-block">
                  <tbody>
                    <tr>
                      <td align='right'>App:</td>
                      <td><span style={{ fontFamily: 'courier' }}>{ props.package.approved_sha1_sum }</span></td>
                    </tr>
                    <tr>
                      <td align='right'>Commit:</td>
                      <td><span style={{ fontFamily: 'courier' }}>{ props.package.commit_hash }</span></td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td align='right' style={{ width: 150 }}>
                <a href = {`${api.defaults.baseURL}/pkgs/download-bin/${role}/${props.package.app_name}/${props.package.app_version}/${props.package.arch}`} target='_new' download>
                  <span  size='sm'>
                    <FaDownload/>
                  </span>
                </a>
                { props.deployable ?
                  <span  size='sm' onClick={this.rapidDeploy.bind(this, 'all', props.package)} style={{ marginLeft: 5 }}>
                    <FaShare/>
                  </span> : null
                }
                  <span  size='sm' onClick={this.remove.bind(this, role, props.package)} style={{ marginLeft: 5, float: 'right' }}>
                    <FaTimes style={{ color: '#F00' }} />
                  </span>
              </td>
            </tr>
        );
    }

    regulatedCount (roleKey) {
      let ret = 0
      for (let pkgKey in this.state.roles[roleKey].check_sums) {
        let pkg = this.state.roles[roleKey].check_sums[pkgKey]
        if (pkg.is_regulated) {
          ret++
        }
      }
      return ret
    }

    Role (idx, props) {
      let rolePkgs = []
      try {
        for (let key in this.state.roles[props.name].check_sums) {
          let pkg = this.state.roles[props.name].check_sums[key]
          if (!this.state.filterRegulated || pkg.is_regulated) {
            rolePkgs.push(pkg)
          }
        }
      } catch (e) {}
      rolePkgs = _.orderBy(rolePkgs, this.getSortField(props.name), this.getSortOrder(props.name))
      return (
        <Accordion key={idx}>
            <Card style={{ overflow: 'visible' }}>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <strong>{ props.name }</strong>
                    </Accordion.Toggle>
                    {
                    props.repoType === 2 ? (
                    <div className="preference">
                      <div style={{ float: 'right' }}>
                        <Row>
                          <Col>{
                            preferenceMap[props.priority] !== undefined ?
                            preferenceMap[props.priority] + ' Priority' : 'Default Priority'
                          }</Col>
                          <Col>
                            <EditGroup
                              getRoles={this.getRoles.bind(this)}
                              group={props.name}
                            />
                        </Col>
                          <Col><FaTrashAlt onClick={this.deleteGroup.bind(this, props.name)}/></Col>
                        </Row>
                      </div>
                    </div> ) : null
                  }
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  {
                    props.pkgs ? (
                      <Table>
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>
                              <span onClick={this.setSortFieldAndOrder.bind(this, props.name, 'app_name')}>
                                App
                              </span>
                              {
                                this.getSortField(props.name)[0] === 'app_name'
                                  ? (this.getSortOrder(props.name) === 'asc' ? <FaCaretUp /> : <FaCaretDown />)
                                  : null
                              }
                            </th>
                            <th>
                              <span onClick={this.setSortFieldAndOrder.bind(this, props.name, 'arch')}>
                                Architecture
                              </span>
                              {
                                this.getSortField(props.name)[0] === 'arch'
                                  ? (this.getSortOrder(props.name) === 'asc' ? <FaCaretUp /> : <FaCaretDown />)
                                  : null
                              }
                            </th>
                            <th>
                              <span onClick={this.setSortFieldAndOrder.bind(this, props.name, 'app_version')}>
                                Version
                              </span>
                              {
                                this.getSortField(props.name)[0] === 'app_version'
                                  ? (this.getSortOrder(props.name) === 'asc' ? <FaCaretUp /> : <FaCaretDown />)
                                  : null
                              }
                            </th>
                            <th><span className="d-none d-lg-block">Sha</span></th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                              rolePkgs.map((pkg, i) => {
                                return this.pkg(i, {package: pkg}, props.name)
                              })
                          }
                        </tbody>
                      </Table>
                    ) : (
                      <Card.Body><span>None</span></Card.Body>
                    )
                  }
                </Accordion.Collapse>
            </Card>
        </Accordion>
      )
    };

    render () {
      const {data} = this.state.filterData
      if (this.state.goToLogin) {
        return <Redirect to='/login' />
      }
      let stagingRolePkgs = []
      try {
        stagingRolePkgs = this.filterStagingPkgs()
      } catch (e) {console.log(e)}
      stagingRolePkgs = _.orderBy(stagingRolePkgs, this.getSortField(stagingRole), this.getSortOrder(stagingRole))
      return (
          <div>
            <Uploader
              getRoles={this.getRoles.bind(this)}
              postUploadDeploy={this.postUploadDeploy.bind(this)}
            />
            <Row>
              <Col>
                <Manifest onComplete={() => {
                  this.getRoles();
                }} />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md="4">
                <CreateGroup getRoles={this.getRoles.bind(this)}/>
              </Col>
              <Col md="4">
                <Form.Check
                  inline
                  label="Show only regulated apps"
                  type="checkbox"
                  id="chkRegulated"
                  checked={this.state.filterRegulated}
                  onChange={() => {
                    this.setState({ filterRegulated: !this.state.filterRegulated })
                  }}
                />
              </Col>
              <Col md="4">
                <Select
                  style = {{width: 30}}
                  options = {options}
                  value={data}
                  onChange={this.handleFilter}/>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Accordion defaultActiveKey="0" className="mt-1">
                  <Card style={{ overflow: 'visible' }}>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <strong>Staged Apps</strong>
                      </Accordion.Toggle>
                      <div style = {{float: 'right'}}>
                        <SelectableContext.Provider value={false}>
                          <Dropdown alignRight size='sm' style={{ marginLeft: 5, float: 'right' }}>
                            <Dropdown.Toggle size='sm' disabled={this.selectedPkgsCount() === 0}>
                              <FaShare /> &nbsp;Deploy {this.selectedPkgsCount() > 0 ?
                                  this.selectedPkgsCount() + ' Package' : ''}
                                  {this.selectedPkgsCount() > 1 ? 's' : ''}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <OverlayTrigger
                                    placement='right'
                                    overlay={
                                      <Tooltip>
                                        Deploy to roles only and not to groups
                                      </Tooltip>
                                    }>
                                    <Dropdown.Item onClick={this.deploy.bind(this, 'all')}>Roles</Dropdown.Item>
                                  </OverlayTrigger>
                                  <Dropdown.Divider />
                                  {
                                    Object.keys(this.state.groups).sort((a, b) => {
                                      if(a < b) {
                                        return -1
                                      }
                                      return 1;
                                    }).map((r, idx) => {
                                      return (
                                        <Dropdown.Item key={idx} onClick={this.deploy.bind(this, this.state.groups[r].name)}>
                                          {this.state.groups[r].name}
                                        </Dropdown.Item>
                                      )
                                    })
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </SelectableContext.Provider>

                            <Button variant='danger' size='sm'
                              disabled={this.selectedPkgsCount() === 0}
                              onClick={this.remove.bind(this, "Staged-Apps", null)}
                              style={{ marginLeft: 5, float: 'right' }}>
                              <FaTimes /> Remove {this.selectedPkgsCount() > 0
                                  ? this.selectedPkgsCount() + ' Package' : ''}
                                  {this.selectedPkgsCount() > 1 ? 's' : ''}
                                </Button>
                                { this.selectedPkgsCount() > 0
                                  ? (
                                    <Button variant='primary'
                                      size='sm'
                                      onClick={ this.selectNone.bind(this) }
                                      style={{ marginLeft: 5, float: 'right' }}>
                                      Check None
                                    </Button>
                                  ) : null
                                }
                              </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              {
                                this.state.roles[stagingRole] && this.state.roles[stagingRole].check_sums ? (
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th><input type='checkbox' checked={this.state.allSel} onChange={this.selectAllPkg.bind(this, stagingRolePkgs)}/> </th>
                                        <th>
                                          <span onClick={this.setSortFieldAndOrder.bind(this, stagingRole, 'app_name')}>
                                            App
                                          </span>
                                          {
                                            this.getSortField(stagingRole)[0] === 'app_name'
                                            ? (this.getSortOrder(stagingRole) === 'asc' ? <FaCaretUp /> : <FaCaretDown />)
                                            : null
                                          }
                                        </th>
                                        <th>
                                          <span onClick={this.setSortFieldAndOrder.bind(this, stagingRole, 'arch')}>
                                            Architecture
                                          </span>
                                          {
                                            this.getSortField(stagingRole)[0] === 'arch'
                                            ? (this.getSortOrder(stagingRole) === 'asc' ? <FaCaretUp /> : <FaCaretDown />)
                                            : null
                                          }
                                        </th>
                                        <th>
                                          <span onClick={this.setSortFieldAndOrder.bind(this, stagingRole, 'app_version')}>
                                            Version
                                          </span>
                                          {
                                            this.getSortField(stagingRole)[0] === 'app_version'
                                            ? (this.getSortOrder(stagingRole) === 'asc' ? <FaCaretUp /> : <FaCaretDown />)
                                            : null
                                          }
                                        </th>
                                        <th><span className="d-none d-lg-block">Sha</span></th>
                                        <th>&nbsp;</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        stagingRolePkgs.map((pkg, i) => {
                                          return this.pkg(i, { package: pkg, deployable: true }, stagingRole)
                                        })
                                      }
                                    </tbody>
                                  </Table>
                                ) : (
                                  <Card.Body><span>None</span></Card.Body>
                                )
                              }
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Col>
                      <Col md="12">
                        {
                          this.state.roles ? Object.keys(this.state.roles).filter(key => {
                            if (key !== stagingRole) {
                              if (this.state.filterRegulated) {
                                return this.regulatedCount(key) > 0
                              }
                              return true
                            }
                          }).sort(sortRoles).map((key, idx) => {
                            let role = this.state.roles[key]
                            return this.Role(idx, {
                              name: key,
                              repoType: role.repo_type,
                              priority: role.priority,
                              pkgs: role.check_sums,
                              newRow: idx % 3 === 0
                            })
                          }) : null
                        }
                      </Col>
                    </Row>
                    <NotificationContainer />
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
          </div>
      );
  };
}

export default RoleMgr
