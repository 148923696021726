import React, { Component } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { FaPencilAlt } from 'react-icons/fa'
import api from '../api/axios'
import Select from 'react-select'

const options = [
  {value: 0, label: "Default Priority"},
  {value: 1, label: "Medium Priority"},
  {value: 2, label: "High Priority"},
  {value: 3, label: "Highest Priority"},
]

class EditGroup extends Component {

  constructor(props){
    super(props)
    this.state = {
      showModal: false,
      data: {value: 0, label: 'Default Priority'}
    }
    this.saveGroupEdit = this.saveGroupEdit.bind(this)
  }

  saveGroupEdit(){
    this.setState({ showModal: false })
    let route = '/pkgs/update-group/' + this.props.group + '/' + this.state.data.value
    console.log("route ", route)
    api.post(route).then(
      res => {
        console.log("res ", res)
        this.props.getRoles()
      }
    )
  }

  handlePriority = data =>{this.setState({data: data})}

  renderModal(){
    const {data} = this.state.data
    return(
      <Modal
        size='lg'
        show={this.state.showModal}
        onHide={() => {}}
        style={styles}
      >
        <Modal.Header>
          <Modal.Title className='h5'>
            Edit Group Priority
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Select
                  options = {options}
                  value={data}
                  placeholder="Priority"
                  onChange={this.handlePriority}/>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' size='sm' onClick={this.saveGroupEdit}>Save</Button>
          <Button variant='danger' size='sm' onClick={() => { this.setState({ showModal: false }) }}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    return(
      <div>
        <FaPencilAlt onClick={()=>{this.setState({showModal: true})}}/>
        {this.renderModal()}
      </div>
    )
  }
}

const styles = {
  modal: {
    top: 40,
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
}

export {EditGroup}
