import axios from 'axios'

const basePath = '/nex7/bc-app-repository'

let baseURL = window.location.hostname === 'localhost'
  // ? 'http://localhost:3006'
  ? 'https://fn-mark.acres.red'
  : 'https://' + window.location.hostname
baseURL += basePath

axios.defaults.withCredentials = true
const api = axios.create({
  baseURL,
  withCredentials: true
})

export default api
