import React, { Component } from 'react'
import { Button,Col, Form, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { FaCheck } from 'react-icons/fa'
import api from '../api/axios'
import Select from 'react-select'


const options = [
  {value: 0, label: "Default Priority"},
  {value: 1, label: "Medium Priority"},
  {value: 2, label: "High Priority"},
  {value: 3, label: "Highest Priority"},
]

class CreateGroup extends Component {

  constructor(props){
    super(props)
    this.state = {
      groups: {},
      showModal: false,
      form: {},
      data: {value: 0, label: 'Default Priority'},
    }

    this.createGroup = this.createGroup.bind(this)
  }

  handlePriority = data =>{this.setState({data: data})}

  createGroup(){
    this.setState({ showModal: false })
    console.log("HERE")
    let route = '/pkgs/create-group/' + this.state.form['group-name'] + '/' +
      this.state.data.value
    console.log("route ", route)
    api.post(route).then(
      res => {
        console.log("res ", res)
        this.props.getRoles()
      }
    )
  }

  handleFormChange = (e) => {
    console.log("val ", e.target.value)
    let fieldName = e.target.name
    // Case Sensitivity trips things up, so make sure all groups that
    // users create are lower case and kebab-cased to prevent spaces
    // from tripping things up on the file system
    let name = e.target.value.replace(/\s+/g, '-').toLowerCase();
    this.setState({form: {...this.state.form, [fieldName]: name}})
  }

  renderModal(){
    const {data} = this.state.data
    return(
      <Modal
        size='lg'
        show={this.state.showModal}
        onHide={() => {}}
        style={styles}
      >
        <Modal.Header>
          <Modal.Title className='h5'>
            Create Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="8">
              <Form.Group>
                <Form.Control type="text" placeholder="Group Name" value={this.state.form['group-name'] || ''}
                  name="group-name"
                  onChange={this.handleFormChange.bind(this)} />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <Select
                  options ={options}
                  value={data}
                  placeholder="Priority"
                  onChange={this.handlePriority}/>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' size='sm' onClick={this.createGroup}>Create</Button>
          <Button variant='danger' size='sm' onClick={() => { this.setState({ showModal: false }) }}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    return(
      <div>
        <Button variant="outline-primary" onClick={()=>{this.setState({showModal: true})}} size='sm'>Create Group <FaCheck/></Button>
        {this.renderModal()}
      </div>
    )
  }
}

const styles = {
  modal: {
    top: 40,
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
}

export {CreateGroup}
