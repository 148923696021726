import React, { Component, Fragment } from 'react'
import { Badge, Button, Card, Col, DropdownButton, Dropdown, ListGroup, Row, Table, Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { FaCheck, FaTimes, FaCircle, FaCaretRight, FaCaretDown, FaSpinner, FaExclamationTriangle } from 'react-icons/fa'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Select from 'react-select'
import { FaDownload } from 'react-icons/fa'
import _ from 'lodash'
import api from '../tools/api/axios'

const stagingRole = 'Staged-Apps'

const clientRefreshRate = 10000

const statusGood = 1
// const statusVersionMismatch = 2 // not currently used
const statusNotRunning = 3

const coordinatorRole = 1
const bridgeControllerRole = 2
const playerTrackingRole = 3
const displayRole = 4
const apiRole = 5
const tableRole = 6
const logRole = 7

const filterTypeError = -1
const filterTypeNone = 0

const sortByIP = (arr, field) => {
  return arr.sort((a, b) => {
    a = a[field].split('.')
    b = b[field].split('.')
    for (let i = 0; i < a.length; i++) {
      if ((a[i] = parseInt(a[i])) < (b[i] = parseInt(b[i]))) {
        return -1
      } else if (a[i] > b[i]) {
        return 1
      }
    }
    return 0
  })
}

const sortBySuspectedDead = (client) => {
  if (client.SuspectedDead) {
    return 1
  }
  return 0
}

const ONLINE_MILLIS = 1000 * 60

class Clients extends Component {

  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      clients: [],
      roles: {},
      selectedClient: null, // modal
      selectedClients: [],  // checkboxes
      nodeIsOpen: {},
      showModal: false,
      sortField: 'IP',
      nodeVerifying: {},
      nodeVerifyResults: {},
      invalidNodes: false,
      showDevTable: [],
      filterType: 0,
      filterGroup: '',
      filterText: ''
    }
    this.refreshClients = true
    this.getClients = this.getClients.bind(this)
    this.getRoles = this.getRoles.bind(this)
  }

  componentDidMount () {
    this.getClients()
    this.getRoles()
  }

  componentWillUnmount () {
    this.refreshClients = false
  }

  getClients () {
    return api.get('/clients/?time='+ (new Date()).getTime())
      .then(res => {
        let clients = res.data
        this.setState({ clients, loading: false })
      })
      .catch(err => {
        this.setState({ loading: false })
        throw err
      }).then(() => {
        if (this.refreshClients) {
          setTimeout(this.getClients.bind(this), clientRefreshRate)
        }
      })
  }

  sendReset (ip,hostname,appname) {
    let rst = {
      Hostname: hostname,
      Appname: appname,
    }
    return api.post('/clients/reset/'+ ip, rst)
      .then(res => {}).catch(err => {})
  }

  filteredClients () {
    let clients = this.filterClientType()
    if (this.state.filterText) {
      let str = this.state.filterText.toLowerCase()
      clients = clients.filter((client, i) => {
        if (client && client.Hostname && client.IP) {
          return client.Hostname.indexOf(str) >= 0 || client.IP.indexOf(str) >= 0
        }
        return false
      })
    }
    if (this.state.sortField === 'IP') {
      clients = sortByIP(clients, 'IP')
      clients = _.orderBy(clients, sortBySuspectedDead)
    } else {
      clients = _.orderBy(clients, [sortBySuspectedDead, 'Hostname'])
    }
    return clients
  }

  getRoles () {
    return api.get('/pkgs/').then(success => {
      let roles = success.data
      this.setState(Object.assign({}, this.state, {roles: roles}))
    }, err => {
      console.error(`Encountered error getting roles ${err}`)
      this.setState({ loading: false })
    }).catch(err => {
      console.error(`Caught error getting roles ${err}`)
      this.setState({ loading: false })
      throw err
    })
  }

  updateClientCache (client) {
    let { clients } = this.state.clients
    for (var i = 0; i < clients.length; i++) {
      if (clients[i].Hostname === client.Hostname) {
        clients[i] = client
        break
      }
    }
    this.setState({ clients })
  }

  addGroup (group) {
    let { clients, selectedClients } = this.state
    let clientsArr = []
    let count = 0
    for (let i = 0; i < clients.length; i++) {
      if (selectedClients.indexOf(clients[i].Hostname) >= 0) {
        if (!clients[i].Groups) {
          clients[i].Groups = []
        }
        if (clients[i].Groups.indexOf(group) === -1) {
          clients[i].Groups.push(group)
          clientsArr.push(clients[i])
          count++
        } else {
          console.log(clients[i])
        }
      }
    }
    let confirmStr = `Add the following ${count} clients to group ${group}?\n\n`
    for (let i = 0; i < clientsArr.length; i++) {
      confirmStr += clientsArr[i].Hostname + '\n'
    }
    if (count > 0 && window.confirm(confirmStr)) {
      api.post('/clients/update/batch', clientsArr)
        .then(res => {
          NotificationManager.success(`Saved`)
          this.setState({ clients, selectedClients: [] })
        })
        .catch(err => {
          let str = 'Error saving clients'
          if (err && err.response && err.response.data) {
            str += err.response.data
          }
          NotificationManager.warning(str)
          return err
        })
    }
  }

  _verify(ip, showModal) {
    let url = '/clients/verify'

    // Set all nodes to verifying
    let nodeVerifying = this.state.nodeVerifying
    let nodeVerifyResults = this.state.nodeVerifyResults  
    let ips = []
    if(ip === "") {
      this.filterClientType().forEach(client => {
        nodeVerifying[client.IP] = true
        nodeVerifyResults[client.IP] = false
        ips.push(client.IP)
      })            
    } else {
      ips.push(ip)
      url = url + "/" + ip
      nodeVerifying[ip] = true
      nodeVerifyResults[ip] = false
    }
    // console.log("Verify Start", ips,nodeVerifying)
    this.setState({
      nodeVerifying: nodeVerifying,
      nodeVerifyResults: nodeVerifyResults,
    })

    let finish = (verifyInfo,nodeVerifyResults) => {
      let nodeVerifying = this.state.nodeVerifying
      ips.forEach(ip => {
        nodeVerifying[ip] = false
      })
      let state = {nodeVerifying: nodeVerifying}
      if(verifyInfo) {
        state.verifyInfo = verifyInfo
      }
      if(nodeVerifyResults) {
        state.nodeVerifyResults = nodeVerifyResults
      }
      this.setState(state)
    }

    // now request data for all nodes
    return api.get(url)
      .then(res => {
        let nodeVerifyResults = this.state.nodeVerifyResults  
        let data = res.data
        let verifyInfo = []
        for(let ip in data ) { // Iterate over each node
          let valid = true // overall node status
          let cmp = data[ip].cmp_list || [] // list of top level validation resutls
          for(let key in cmp){
            let pkgValidates=(cmp[key].status === statusGood)
            valid = valid && pkgValidates
            if(showModal) {
              verifyInfo.push({pkg: cmp[key], validates: pkgValidates})
            }
          }

          let route = data[ip].route_list || [] // now we iterate over routes as required
          for(let rkey in route) {
            let rdata = route[rkey] || {}
            let rip = rdata.ip
            let cmp = rdata.sums || [] // list of top level validation resutls
            for(let key in cmp){
              let pkgValidates=(cmp[key].status === statusGood)
              valid = valid && pkgValidates
              if(showModal) {
                cmp[key].app_name = rip + ":" + cmp[key].app_name
                verifyInfo.push({pkg: cmp[key], validates: pkgValidates})
              }
            }  
          }
          nodeVerifyResults[ip] = valid 
        }
        finish(verifyInfo,nodeVerifyResults)
      }).catch(err =>{  
        finish(undefined,undefined)
      })
  }
  
  verify (client, showModal) {
    this.setState({
      verifyInfo: null,
      selectedClient: client,
      showModal,
    })
    return this._verify(client.IP,showModal)
  }

  verifyAllNodes () {
    let clients = this.filterClientType()
    if(clients.length === this.state.clients.length) {
      return this._verify("",false)
    }
    clients.forEach(client => {
      this._verify(client.IP,false)
    })
  }

  // getClientByIP - returns client object by ip
  getClientByIP (ip) {
    for (let i = 0; i < this.state.clients.length; i++) {
      if (this.state.clients[i] && this.state.clients[i].IP === ip) {
        return this.state.clients[i]
      }
    }
    return null
  }

  // getClientByHostname - returns client object by hostname
  getClientByHostname (hostname) {
    for (let i = 0; i < this.state.clients.length; i++) {
      if (this.state.clients[i] && this.state.clients[i].Hostname === hostname) {
        return this.state.clients[i]
      }
    }
    return null
  }

  // noResponseNodes - nodes that failed to respond to request for validation
  noResponseNodes () {
    let clients = []
    let { nodeVerifyResults, nodeVerifying } = this.state
    for (let ip in nodeVerifyResults) {
      if (!nodeVerifying[ip] && nodeVerifyResults[ip] === undefined) {
        let client = this.getClientByIP(ip)
        if (client && !client.SuspectedDead) {
          clients.push(client)
        }
      }
    }
    return clients
  }

  // verifyNoResponseNodes - verifies only those returned by noResponseNodes -
  // these nodes failed to respond to request for validation
  verifyNoResponseNodes () {
    this.noResponseNodes().forEach((client, i ) => {
      this.verify(client, false)
    })
  }

  openAllNodes (open) {
    if (open) {
      this.setState({ openAllNodes: true })
    } else {
      this.setState({ openAllNodes: false, nodeIsOpen: [] })
    }
  }

  nodeIsOpen (client) {
    return this.state.openAllNodes || this.state.nodeIsOpen[client.IP]
  }

  toggleNodeOpen (client) {
    let nodeIsOpen = this.state.nodeIsOpen
    nodeIsOpen[client.IP] = !nodeIsOpen[client.IP]
    this.setState({ nodeIsOpen })
  }

  statusColor (client) {
    if (client.SuspectedDead) {
      return 'red'
    } else {
      if (client.LastHeartbeat) {
        let now = new Date().getTime()
        let hb = client.LastHeartbeat / 1000000
        if (now - hb > ONLINE_MILLIS) {
          return 'red'
        }
      }
    }
    return 'green'
  }

  deleteClient(ip){
    let route = '/clients/delete/' + ip
    return api.post(route)
      .then(res => {window.alert('Deleted client: ' + ip)})
      .catch(err => {window.alert("Couldn't delete client: " + ip)
        return err})
  }

  getBorderColor(client){
    switch(client.RoleType) {
      case coordinatorRole: return 'dark';
      case bridgeControllerRole: return 'primary';
      case displayRole: return 'success';
      case tableRole: return 'warning';
      //
      case playerTrackingRole:
      case apiRole:
      case logRole: return 'info';
      default: 
    }
    return 'danger';
  }

  getNodeVerifyIcon (client) {
    let validates = this.state.nodeVerifyResults[client.IP]
    if (validates) {
      return <FaCheck style={{ marginLeft: 10, color: 'green' }} />
    } else {
      if (validates === false) {
        return <FaExclamationTriangle style={{ marginLeft: 10, color: 'red' }} />
      }
    }
    return null
  }

  setClientRoles (client, evt) {
    let confirmStr = ''
    let changed = false
    if (!client.Groups) {
      client.Groups = []
    }
    if (evt.action === 'remove-value') {
      for (let i = 0; i < client.Groups.length; i++) {
        if (client.Groups[i] === evt.removedValue.label) {
          confirmStr = `Remove ${client.Groups[i]} from ${client.Hostname}?`
          client.Groups.splice(i, 1)
          changed = true
          break
        }
      }
    } else if (evt.action === 'select-option') {
      if (client.Groups.indexOf(evt.option.label) === -1) {
        confirmStr = `Add ${evt.option.label} to ${client.Hostname}?`
        client.Groups.push(evt.option.label)
        changed = true
      }
    }
    if (changed && window.confirm(confirmStr)) {
      return api.post('/clients/update', client)
        .then(() => {
          let { clients } = this.state
          for (let i = 0; i < clients.length; i++) {
            if (client.Hostname === clients[i].Hostname) {
              clients[i].Groups = client.Groups
            }
          }
          this.setState({ clients })
        })
        .catch(err => {
          console.log('setClientRoles err ', err)
          window.alert('Unable to save node.')
          return err
        })
      }
  }

  renderNodes () {
    if (this.state.loading) {
      return 'Loading...'
    }
    return (
      <div>
        {
          this.filteredClients().map((client, i) => {
            let summaries = _.sortBy(client.Summaries.sums, 'app_name')
            let rl = client.Summaries.route || {}
            let routes = rl.sums || []
            let icon = this.state.nodeVerifying[client.IP]
              ? <FaSpinner style={{ marginRight: 10 }} className='icon-spin' />
              : <FaCircle style={{ marginRight: 10, color: this.statusColor(client) }} />
            return (
              <Card
                bg= 'light'
                key={i}
                style={styles.nodeCard}
              >
                <Card.Header style={styles.nodeCardHeader} onClick={this.toggleNodeOpen.bind(this, client)}>
                  <div style={{ float: 'left' }}>
                    <Form.Check
                      type="checkbox"
                      label=""
                      style={{ float: 'left', padding: 0, marginLeft: 10, marginRight: 20 }}
                      checked={this.state.selectedClients.indexOf(client.Hostname) >= 0}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        let { selectedClients } = this.state
                        let idx = selectedClients.indexOf(client.Hostname)
                        if (idx >= 0) {
                          selectedClients.splice(idx, 1)
                        } else {
                          selectedClients.push(client.Hostname)
                        }
                        this.setState({ selectedClients })
                      }}
                    />
                    { icon }
                    { client.IP } <span className='small' style={{color:'#777'}}>({ client.Hostname.trim() })</span>
                    { this.getNodeVerifyIcon(client) }
                    { client.SuspectedDead ? <small><br />(SUSPECTED DEAD)</small> : null }
                  </div>
                  <div style={{ float: 'right' }}>
                    <span
                      className='text-primary'
                      style={{ marginRight: 20 }}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.verify(client, true)
                      }}>
                      Verify
                    </span>
                    {
                      this.nodeIsOpen(client)
                        ? <FaCaretDown />
                        : <FaCaretRight />
                    }
                    { client.SuspectedDead ? <FaTimes style={{ color: '#F00' }} onClick={this.deleteClient.bind(this, client.IP)}/> : null }
                  </div>
                  <div style={{ clear: 'both' }} />
                </Card.Header>
                {
                  this.nodeIsOpen(client) ? (
                    <Card.Body style={{ padding: 0, margin: 0 }}>
                      <Table size='sm'>
                        <tbody>
                          {
                            summaries.map((summary, j) => {
                              return (
                                <tr key={j} style={styles.style1}>
                                  <td>{ summary.app_name }</td>
                                  <td>{ summary.app_version }</td>
                                </tr>
                              )
                            })
                          }
                          {
                            routes.map((summary, j) => {
                              return (
                                <tr key={j} style={styles.style1}>
                                  <td>TSD: { summary.app_name }</td>
                                  <td>{ summary.app_version }</td>
                                </tr>
                              )
                            })                            
                          }
                        </tbody>
                      </Table>
                      <div style={{ padding: 20 }}>
                        <span style={{ float: 'left', marginRight: 10, marginTop: 8 }}>Groups:</span>
                        <Select
                          isMulti
                          value={client.Groups ? client.Groups.map((groupName, i) => {
                            return { label: groupName }
                          }) : null}
                          options={
                            Object.keys(this.state.roles).filter((key, i) => {
                              let role = this.state.roles[key]
                              // filter staged & roles, only show groups
                              if (role.name === 'Staged-Apps' || role.role_type) {
                                return false
                              }
                              return true
                            }).map((key, i) => {
                              let role = this.state.roles[key]
                              return { value: role.name, label: role.name }
                            })
                          }
                          onChange={(value, action) => {
                            this.setClientRoles(client, action)
                          }}
                        />
                      </div>
                    </Card.Body>
                  ) : null
                }
              </Card>
            )
          })
        }
      </div>
    )
  }

  toggleDeviceTable(name){
    let dt = this.state.showDevTable
    if(dt[name] === undefined){
      dt[name] = false
    }
    dt[name] = !dt[name]
    this.setState({showDevTable: dt})
  }

  renderResetButton(ip, hostname, appname) {
    let name = appname === "os-65f51bf3f39a920627b0e3ffffc49416219c22d9" ? "Rbt" : "Rst"
    return <Button variant='danger' size='sm' onClick={() => { 
      this.sendReset(ip,hostname,appname)
      console.log("Reset", ip, hostname, appname,name)
    }}>{name}</Button>
  }

  renderReset(ip, hostname, appname) {
    if(appname === "") {
      appname = "bc-app-installer"
    }
    let fields = appname.split(":")
    if( fields.length == 2 ) {
      hostname = fields[0]
      appname = fields[1]
    }
    if( (appname === 'bc-pkg-builder') || (appname === 'bc-console') || 
      (appname === 'bc-comm') || (appname === 'tag-installer') ||
      appname.endsWith('.tgz') || appname.endsWith('.json') || 
      appname.endsWith('.bin') ) {
        return '';
    }
    let resetAppName = appname 
    let hasReset = true
    let hasReboot = appname === "bc-app-installer" 
    return <div>
      { hasReset ? this.renderResetButton(ip,hostname,resetAppName) : '' }
      { hasReboot ? this.renderResetButton(ip,hostname,"os-65f51bf3f39a920627b0e3ffffc49416219c22d9") : '' }
    </div>
  }


  renderModal () {
    if (!this.state.selectedClient) {
      return null
    }
    let vi = this.state.verifyInfo || {}
    let hostname =  this.state.selectedClient.Hostname
    let ip = this.state.selectedClient.IP
    return (
      <Modal
        size='lg'
        show={this.state.showModal}
        onHide={() => {}}
        style={styles}
      >
        <Modal.Header>
          <Modal.Title className='h5'>
            { this.state.selectedClient.IP }
          </Modal.Title>
          { this.renderReset(ip, hostname,"") }
        </Modal.Header>
        <Modal.Body>
          {
            this.state.nodeVerifying[this.state.selectedClient.IP] ? <h6><i>Verifying...</i></h6> : null
          }
          <div>
            <Table striped size='sm' style={{ margin: 0, padding: 0 }}>
              <thead>
                <tr>
                  <th>App</th>
                  <th>Version</th>
                  <th>Sha1</th>
                  <th>Valid</th>
                  <th>Reset/Reboot</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(vi).filter(i => {
                    return vi[i].pkg !== undefined && vi[i].pkg.actual !== undefined
                  }).map((i, idx) => {
                    return (
                      <Fragment key={idx}>
                      <tr
                        style={{ cursor: vi[i].pkg.actual.length > 1 ? 'pointer' : '' }}
                        key={idx}
                        onClick={(e) => {
                          if (vi[i].pkg.actual.length > 1) {
                            this.toggleDeviceTable(vi[i].pkg.app_name)
                          }
                        }}
                      >
                        <td>{ vi[i].pkg.app_name }{ vi[i].pkg.actual.length > 1 ? (this.state.showDevTable[vi[i].pkg.app_name] ? <FaCaretDown/> : <FaCaretRight/>) : null }</td>
                        <td>
                          { vi[i].pkg.desired_version  ?
                            <div>{ vi[i].pkg.desired_version}</div> : null
                          }
                          {
                            ((vi[i].pkg.actual) && ((vi[i].pkg.desired_version
                              !== vi[i].pkg.actual[0].version) && vi[i].pkg.actual[0].version !== undefined)) ?
                              <div style ={{color: 'red'}}>actual: { vi[i].pkg.actual_app_version }</div> : null
                          }
                        </td>
                        <td>
                          {
                            (vi[i].status !== statusNotRunning)
                            ? <div>{ vi[i].pkg.desired_sha1_sum }</div>
                            : <div style ={{color: 'red'}}>{"App not running. No verification info."}</div>
                          }
                          {
                            ((vi[i].pkg.actual) && ((vi[i].pkg.actual[0].sha1_sum
                            !== vi[i].pkg.desired_sha1_sum) && vi[i].pkg.actual[0].sha1_sum !== undefined)) ?
                             <div style ={{color: 'red'}}>actual: { vi[i].pkg.actual[0].sha1_sum }</div> : null
                          }
                        </td>
                        <td>
                          {
                            vi[i].validates ?
                              <FaCheck style={{ color: 'green' }} /> :
                              <FaTimes style={{ color: 'red' }} />
                          }
                        </td>
                        <td>
                          { 
                          this.renderReset(ip,hostname,vi[i].pkg.app_name)
                          }
                        </td>
                      </tr>
                      {
                        vi[i].pkg.actual.length > 1 && this.state.showDevTable ?
                        Object.keys(vi[i].pkg.actual).map((ac, z) => {
                          return(
                            <Fragment key={z}>
                              {
                                this.state.showDevTable[vi[i].pkg.app_name] ? <tr style={{backgroundColor: '#f2f2f2'}}>
                                  <td>{vi[i].pkg.actual[z].device}</td>
                                  <td>
                                    {vi[i].pkg.actual[z].version !== undefined ?
                                      vi[i].pkg.actual[z].version : null}
                                  </td>
                                  <td>
                                    {vi[i].pkg.actual[z].sha1_sum !== undefined ?
                                      vi[i].pkg.actual[z].sha1_sum : null}
                                  </td>
                                  <td></td>
                                </tr> : null
                              }

                            </Fragment>

                          )
                        }) : null

                      }

                      </Fragment>
                  )})
                }
              </tbody>
            </Table>
          </div>
          <p>&nbsp;</p>
          <div>
            <h6>Last Update: { new Date(this.state.selectedClient.LastHeartbeat).toLocaleString() }</h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' size='sm' onClick={() => { this.setState({ showModal: false }) }}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  setFilterType(type){
    if(this.state.filterType !== 0){
      if(this.state.filterType === type){
        this.setState({filterType: 0})
        return
      }
    }
    this.setState({filterType: type, selectedClients: [] })
  }

  setFilterGroup(group) {
    if(this.state.filterGroup !== '') {
      if(this.state.filterGroup === group) {
        this.setState({filterGroup: ''})
        return
      }
    }
    this.setState({filterGroup: group, selectedClients: [] })
  }

  checkNodeVerify () {
    if(Object.entries(this.state.nodeVerifyResults).length !== 0){
      for(let key in this.state.nodeVerifyResults){
        if(!this.state.nodeVerifyResults[key]){
          return false
        }
      }
    }
    return true
  }

  filterClientType () {
    let clients = []
      switch(this.state.filterType){
        case filterTypeError:
          clients = this.state.clients.filter(c => {
            return !this.state.nodeVerifyResults[c.IP]
          });
          break;
        case filterTypeNone:
          clients = this.state.clients
          break;
        default:
          clients = this.state.clients.filter(c => {
            return c.RoleType === this.state.filterType;
          });
          break
      }

    return this.filterClientGroup(clients)
  }

  filterClientGroup (clients) {
    return this.state.filterGroup === '' ? clients : clients.filter(c => {
      if(c.Groups !== null) {
        return c.Groups.filter(g => {
          return g === this.state.filterGroup
        }).length > 0
      }
      return false
    })
  }

  clientCountByRoleOrGroup (role) {
    try {
      if (role.role_type) {
        return this.state.clients.filter((client, i) => {
          return client.RoleType === role.role_type
        }).length
      } else {
        return this.state.clients.filter((client, i) => {
          return client.Groups ? client.Groups.indexOf(role.name) >= 0 : false
        }).length
      }
    } catch (e) { console.log(e) }
    return 0
  }

  clientCountByValidationFailures() {
    try {
      let vr = this.state.nodeVerifyResults 
      let num = 0
      for(let ip in vr) {
        if( !vr[ip] ) num++
      }
      return num
    } catch (e) { console.log(e) }
    return 0
  }

  removeAllFromGroup () {
    let role = null
    for (let roleName in this.state.roles) {
      if (roleName === this.state.filterGroup) {
        role = this.state.roles[roleName]
      }
    }
    if (!role) {
      return
    }
    if (window.confirm(`Remove ${this.clientCountByRoleOrGroup(role)} client(s) from group ${this.state.filterGroup}`)) {
      let clientsArr = []
      let { clients, filterGroup } = this.state
      for (let i = 0; i < clients.length; i++) {
        let idx = clients[i].Groups.indexOf(filterGroup)
        if (clients[i].Groups && idx >= 0) {
          clients[i].Groups.splice(idx, 1)
          clientsArr.push(clients[i])
        }
      }
      api.post('/clients/update/batch', clientsArr)
        .then(res => {
          NotificationManager.success(`Saved`)
          this.setState({ clients })
        })
        .catch(err => {
          let str = 'Error saving clients'
          if (err && err.response && err.response.data) {
            str += err.response.data
          }
          NotificationManager.warning(str)
          return err
        })
    }
  }

  groupKeys () {
    return Object.keys(this.state.roles).filter((key, i) => {
      if (key === 'Staged-Apps') {
        return false
      }
      let role = this.state.roles[key]
      if (role.role_type || !this.clientCountByRoleOrGroup(role)) {
        return false
      }
      return true
    })
  }

  renderSort () {
    return <div>
      <h6>Sort</h6>
      <ListGroup style={{cursor: 'pointer'}}>
        {
          ['IP', 'Hostname'].map((sortFieldName, i) => {
            return <ListGroup.Item
                key={i}
                active={this.state.sortField === sortFieldName}
                onClick={() => this.setState({sortField: sortFieldName})}
            >
              {sortFieldName}
            </ListGroup.Item>
          })
        }
      </ListGroup>
    </div>
  }

  renderSearch () {
    return <div style={{ marginTop: 20 }}>
      <h6>Search</h6>
      <Form.Control
          type='text'
          placeholder='Hostname or IP'
          onChange={(e) => {
            this.setState({ filterText: e.target.value })
            this.setFilterGroup('')
            this.setFilterType(0)
          }}
      />
    </div>
  }

  renderFilterByRole () {
    return <div style={{ marginTop: 20 }}>
      <h6>Filter by Role</h6>
      <ListGroup style={{ cursor: 'pointer' }}>
        {
          Object.keys(this.state.roles).map((key, i) => {
            if (key === 'Staged-Apps') {
              return null
            }
            let role = this.state.roles[key]
            if (!role.role_type || !this.clientCountByRoleOrGroup(role)) {
              return null
            }
            let displayName = role.name.replace('-Role', '')
            return <ListGroup.Item
                key={i}
                className='d-flex justify-content-between align-items-start'
                active={this.state.filterType === role.role_type || this.state.filterGroup === role.name}
                onClick={() => {
                  this.setFilterGroup('')
                  this.setFilterType(role.role_type)
                }
                }>
              { displayName }
              <Badge variant='secondary' pill>
                { this.clientCountByRoleOrGroup(role) }
              </Badge>
            </ListGroup.Item>
          })
        }
      </ListGroup>
    </div>
  }

  renderFilterByGroup () {
    if (this.groupKeys().length === 0) {
      return null
    }
    return <div style={{ marginTop: 20 }}>
      <h6>Filter by Group</h6>
      <ListGroup style={{ cursor: 'pointer' }}>
        {
          this.groupKeys().map((key, i) => {
            if (key === 'Staged-Apps') {
              return null
            }
            let role = this.state.roles[key]
            if (role.role_type || !this.clientCountByRoleOrGroup(role)) {
              return null
            }
            let displayName = role.name.replace('-Role', '')
            return <ListGroup.Item
                key={i}
                className="d-flex justify-content-between align-items-start"
                active={this.state.filterType === role.role_type || this.state.filterGroup === role.name}
                onClick={() => {
                  this.setFilterGroup(role.name)
                  this.setFilterType(0)
                }
                }>
              { displayName }
              <Badge variant="secondary" pill>
                { this.clientCountByRoleOrGroup(role) }
              </Badge>
            </ListGroup.Item>
          })
        }
      </ListGroup>
    </div>
  }

  renderFilterByError () {
    return <div style={{ marginTop: 20 }}>
      <h6>Filter by Error</h6>
      <ListGroup style={{ cursor: 'pointer' }}>
        <ListGroup.Item
          className="d-flex justify-content-between align-items-start"
          active={this.state.filterType === filterTypeError}
          onClick={() => { 
            this.setFilterType(filterTypeError)
          }
          }>
            Validation Failures
            <Badge variant="secondary" pill>
                { this.clientCountByValidationFailures() }
            </Badge>
        </ListGroup.Item>
      </ListGroup>
    </div>
  }

  renderDownload () {
    return <div style={{ marginTop: 20 }}>
      <a href={`${api.defaults.baseURL}/clients/csv`}>
        <Button variant='' size='sm'>
          <FaDownload /> CSV
        </Button>
      </a>
    </div>
  }

  render () {
    return (
      <div>
        <NotificationContainer />
        <Row style={{ marginTop: 20 }}>

          { /* LEFT COLUMN - DISPLAY OPTS */ }
          <Col sm={3}>
            { this.renderSort() }
            { this.renderSearch() }
            { this.renderFilterByRole() }
            { this.renderFilterByGroup() }
            { this.renderFilterByError() }
            { this.renderDownload() }
          </Col>

          { /* RIGHT COLUMN - NODES */ }
          <Col>
            <div className="d-flex justify-content-between align-items-start">
              <h6>
                Client Nodes&nbsp;
                {
                  this.filteredClients().length < this.state.clients.length
                    ? <span style={{ color: '#AFAFAF' }}>({ this.filteredClients().length }/{ this.state.clients.length })</span>
                    : <span style={{ color: '#AFAFAF' }}>({ this.state.clients.length })</span>
                }
              </h6>
              <div>
                {
                this.state.clients.length > 0 ? (
                  <div>
                      <Button onClick={this.openAllNodes.bind(this, !this.state.openAllNodes)} variant='outline-secondary' size='sm'>
                        { this.state.openAllNodes ? 'Close' : 'Open' } All
                      </Button>
                      <Button onClick={this.verifyAllNodes.bind(this)} variant='outline-secondary' size='sm' style={{ marginLeft: 10 }}>
                        Verify All
                      </Button>
                      {
                        this.noResponseNodes().length
                          ? (
                            <Button onClick={this.verifyNoResponseNodes.bind(this)} variant='outline-secondary' size='sm' style={{ marginLeft: 10 }}>
                              Verify Failed (no response)
                            </Button>
                          ) : null
                      }
                      {
                        this.state.filterGroup && this.filteredClients().length
                          ? (
                            <Button
                              size='sm'
                              variant='outline-primary'
                              onClick={this.removeAllFromGroup.bind(this)}
                              style={{ marginLeft: 10 }}
                            >
                              Remove all from { this.state.filterGroup }
                            </Button>
                          ) : null
                      }
                      <DropdownButton
                        variant='outline-primary'
                        title='Add to Group'
                        size='sm' style={{ float: 'right', marginLeft: 10 }}
                        disabled={!this.state.selectedClients.length}
                      >
                        {
                            Object.keys(this.state.roles).filter(key => {
                              if (key === stagingRole || this.state.roles[key].role_type) {
                                return false
                              }
                              return true
                            }).sort((a, b) => {
                              if (a < b) return -1
                              return 1
                            }).map((r, idx) => {
                              return (
                                <Dropdown.Item key={idx} onClick={this.addGroup.bind(this, r)}>
                                  {r}
                                </Dropdown.Item>
                              )
                            })
                        }
                      </DropdownButton>
                  </div>
                ) : null
              }
              </div>
            </div>
            <br />
            { this.renderNodes() }
            <br />
          </Col>
        </Row>
        { this.renderModal() }
        <p>&nbsp;</p>
      </div>
    )
  }
}

const styles = {
  style1: {
    color: '#777',
    wordWrap: 'break-word'
  },
  style2: {
    color: '#777',
    fontWeight: 600
  },
  modal: {
    top: 40,
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  nodeCard: {
    marginBottom: 10,
    verticalAlign: 'top'
  },
  nodeCardHeader: {
    cursor: 'pointer'
  },
  bridgeSwatch:{
    height: 15,
    width: 15
  }
}

export default Clients
